import { Injectable } from '@angular/core';
import { AvailableLockTypeModel } from '../models/AvailableLockTypeModel';
import { LockvueBaseConfigService } from './lockvue-base-config.service';

@Injectable({
  providedIn: 'root',
})
export class LockvueConfigProviderService extends LockvueBaseConfigService {
  constructor() {
    super();
  }

  /**
   * Name of the theme to be used for the front-end dashboard
   */
  getTheme(dark?: boolean): string {
    if (dark) {
      return 'allied_dark';
    }
    return 'allied_light';
  }

  /**
   * Gets the friendly name of the client such as 'LockVue' or
   * the name of the white-label partner for whom this front-end
   * is deployed.
   */
  getClientName(): string {
    return 'RockIT';
  }

  /**
   * Whether third-party integrations (such as YesBookIt) are
   * enabled (i.e. if those settings should be displayed at all)
   * or not.
   */
  integrationsAllowed(): boolean {
    return false;
  }

  /**
   * Returns the default center of the map shown on the dashboard homepage.
   * The returned object contains the following attributes:
   * { latitude, longitude, zoom }
   */
  getDashboardMapCenter(): any {
    return {
      latitude: -30,
      longitude: 150,
      zoom: 3,
    };
  }
  /**
   * Returns the list of lock manufacturers available for this deployment.
   * The list is returned in the priority order suitable for this deployment.
   */
  getLockManufacturers(): string[] {
    return ['Allied'];
  }

  /**
   * Returns the list of lock types (manufacturers) and their subtypes
   * in the order suitable for this deployment.
   */
  getLockTypes(): AvailableLockTypeModel[] {
    return [
      {
        manufacturer: 'Allied Locks',
        subtypes: ['Dominator', 'HD Padlock'],
      },
    ];
  }

  /**
   * Returns the client url to be used in the PDF reports
   */

  getClientURL(): string {
    return 'go.rockitlock.com';
  }

  /**
   * Returns Image and coordinates for PDF Reports
   */
  getLogoAndCoordinates(): any {
    const img = new Image();
    img.src = '../../assets/images/rockit_only_text.png';
    return {
      image: img,
      x: undefined,
      y: 10,
      width: 120,
      height: 10,
    };
  }

  getSupportEmail(): string {
    return 'support@rockitlock.zendesk.com';
  }

  getDWLClientName(): string {
    return 'RockIT';
  }

  getClientSpecificUrls(): { support: string; privacy: string; terms: string } {
    return {
      support: 'https://rockitlock.com/web-portal-support/',
      privacy: 'https://alliedlocks.co.nz/wp-content/uploads/2021/12/RockIT-Privacy-Policy.pdf',
      terms: 'https://alliedlocks.co.nz/wp-content/uploads/2021/12/RockIT-Terms-of-Use.pdf',
    };
  }
}
